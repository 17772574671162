@import 'variables';
@import 'mixins';
@import 'typography';
@import 'normalize';
@import "fluid";
@import "jarallax";

body{
  font-family: $primary-font;
	font-weight: 300;
  font-size: 16px;
  line-height: 1.6;
	font-style: normal;
  color: $white;
  background-color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main{
  z-index: 1;
}
.container{

}
.span-all{
  grid-column: 1/-1;
}
