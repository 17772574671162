
p {
  font-family: $primary-font;
	font-weight: 300;
  @include fluid-type(font-size, 300px, 1600px, 14px, 17px);
  @include fluid-type(line-height, 300px, 1600px, 22px, 26px);
  padding: 0 0 $gutter/2 0;
  margin: 0;
  letter-spacing: .01rem;
  &:last-child{
    padding: 0;
  }
}
h1,h2,h3,h4,h5,h6 {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1{
  font-family: $secondary-font;
  font-weight: 300;
  @include fluid-type(font-size, 300px, 1600px, 40px, 62px);
  @include fluid-type(line-height, 300px, 1600px, 44px, 68px);
  margin: 0 0 0 -3px;
}

h2{
  font-family: $secondary-font;
  font-weight: 300;
  @include fluid-type(font-size, 300px, 1600px, 36px, 42px);
  @include fluid-type(line-height, 300px, 1600px, 40px, 50px);
  letter-spacing: .04rem;
  margin: 0 0 $gutter 0;
}

h3{
  font-weight: 800;
  @include fluid-type(font-size, 300px, 1600px, 18px, 20px);
  @include fluid-type(line-height, 300px, 1600px, 22px, 24px);
  letter-spacing: -.03rem;
  margin: 0;
}

h4{
  font-family: $secondary-font;
  font-weight: 700;
  @include fluid-type(font-size, 300px, 1600px, 22px, 28px);
  @include fluid-type(line-height, 300px, 1600px, 24px, 30px);
  letter-spacing: .04rem;
  margin: 0 0 $gutter 0;
  color: $primary-color;
}

h5{
  font-weight: 500;
  @include fluid-type(font-size, 300px, 1600px, 14px, 16px);
  @include fluid-type(line-height, 300px, 1600px, 18px, 22px);
  letter-spacing: -.01rem;
  text-transform: none;
  margin: 0 0 $spacer-sm 0;
}

a {
  color: $primary-color;
  font-weight: 600;
  transition: all .3s ease;
  text-decoration: none;
  &:hover{
    transition: all .3s ease;
    color: $white;
  }
}
