
// HERO

section#hero {
  background-color: $white;
  margin-bottom: $page-margin;
  .container{
    display: grid;
    grid-template-columns: 1fr;
  }
  article{
    padding: 0 $page-margin;
    &.hero-content{
      grid-column: 1/span 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 50vh;
      color: $secondary-color;
      padding-bottom: $spacer;
    }
    h5{
      color: black;
    }
    &.hero-image{
      grid-column: 1/span 1;
      min-height: 50vh;
    }
  }
}


// WORK

section#work {
  padding: $spacer $page-margin $spacer/2 $page-margin;
  .container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: $gutter;
  }
  h4{
    margin-bottom: 0;
  }
  a{
    article{
      &.work-project{
        display: flex;
        align-items: flex-start;
        min-height: 25vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 33%;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba($black, .9);
          transition: all .3s ease;
        }
        .work-project-info{
          color: $white;
          position: relative;
          z-index: 1;
          padding: $gutter;
          text-shadow: 1px 2px 4px rgba($black, .3);
        }
      }
    }
    &:hover{
      article{
        &.work-project{
          &:after {
            background: rgba($black, .1);
            transition: all .3s ease;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .container{
      grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }
    a{
      article{
        &.work-project{
          min-height: 35vw;
        }
      }
    }
  }
  @media screen and (max-width: 567px) {
    .container{
      grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    }
    a{
      article{
        &.work-project{
          min-height: 65vw;
        }
      }
    }
  }
}


// ABOUT

section#about {
  padding: $spacer/2 $page-margin $spacer/2 $page-margin;
  .container{
    display: grid;
    grid-template-columns: 66%;
  }
  article{
    &.about-content{
      grid-column: 1/span 1;
      h5{
        margin-top: $gutter;
      }
      ul{
        margin: 0;
        padding: 0;
        li{
          display: inline;
          list-style-type: none;
          font-family: $primary_font;
          font-weight: 100;
          @include fluid-type(font-size, 300px, 1600px, 28px, 56px);
          @include fluid-type(line-height, 300px, 1600px, 34px, 64px);
          color: lighten($secondary-color, 15%);
          &:after {
            content: '|';
            color: darken($secondary-color, 3%);
            margin: 0 10px;
            z-index: 1;
            transition: all .3s ease;
          }
          &:last-child{
            &:after{
              opacity: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .container{
      grid-template-columns: 100%;
    }
  }
}



// CONTACT

section#contact {
  padding: $spacer/2 $page-margin $spacer/2 $page-margin;
  margin-bottom: $page-margin;
  .container{
    display: grid;
    grid-template-columns: 66%;
  }
  article{
    &.contact-content{
      grid-column: 1/span 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .copy-wrapper{
        grid-column: 1/span 2;
        grid-row: 1/span 1;
        margin-bottom: $gutter;
        ul{
          margin: 0;
          padding: 0;
          li{
            display: block;
            list-style-type: none;
            font-weight: 300;
            @include fluid-type(font-size, 300px, 1600px, 16px, 20px);
            line-height: normal;
            &.strong{
              font-weight: 700;
            }
          }
        }
      }
      .address-wrapper{
        grid-column: 1/span 1;
        grid-row: 2/span 1;
        ul{
          margin: 0;
          padding: 0;
          li{
            display: block;
            list-style-type: none;
            font-weight: 300;
            @include fluid-type(font-size, 300px, 1600px, 16px, 20px);
            line-height: normal;
            &.strong{
              font-weight: 700;
            }
          }
        }
      }
      .social-wrapper{
        grid-column: 2/span 1;
        grid-row: 2/span 1;
        ul{
          margin: 0;
          padding: 0;
          li{
            display: inline-block;
            list-style-type: none;
            @include fluid-type(font-size, 300px, 1600px, 30px, 50px);
            padding-right: 10px;
            line-height: normal;
            i{
              font-weight: normal;
            }
          }
        }
      }
    }
    &.contact-form-wrapper{
      grid-column: 1/span 1;
      padding: $spacer 0;
      input[type="text"],
      input[type="email"],
      textarea,
      button[type="submit"] {
        font-family: $primary_font;
        font-weight: 300;
        font-size: 18px;
      }
      fieldset {
        &.honeypot-wrapper{
          display: none;
        }
        border: none !important;
        margin: 0 0 10px 0;
        min-width: 100%;
        padding: 0;
        width: 100%;
      }
      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        border: 0;
        background: #FFF;
        margin: 0 0 5px;
        padding: 20px;
      }
      input[type="text"]:hover,
      input[type="email"]:hover,
      textarea:hover {
        transition: border-color 0.3s ease-in-out;
        border: 0;
      }
      textarea {
        height: 200px;
        max-width: 100%;
        resize: none;
      }
      button[type="submit"] {
        cursor: pointer;
        width: auto;
        border: none;
        background: $primary-color;
        color: #FFF;
        margin: 0;
        padding: 10px $gutter;
        font-size: 18px;
        font-weight: 700;
      }
      button[type="submit"]:hover {
        background: darken($primary-color, 8%);
        -webkit-transition: background 0.3s ease-in-out;
        -moz-transition: background 0.3s ease-in-out;
        transition: background-color 0.3s ease-in-out;
      }
      button[type="submit"]:active {
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
      }
      input:focus,
      textarea:focus {
        outline: 0;
        border: 0;
      }
      ::-webkit-input-placeholder {
        color: #888;
      }
      :-moz-placeholder {
        color: #888;
      }
      ::-moz-placeholder {
        color: #888;
      }
      :-ms-input-placeholder {
        color: #888;
      }
      .honeypot{
        display: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .container{
      grid-template-columns: 100%;
    }
    article{
      &.contact-form-wrapper{
        input[type="text"],
        input[type="email"],
        textarea {
          width: 87%;
        }
      }
    }
  }
}


// WORK DETAIL

section#work-detail {
  .container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: $header-size auto auto;
  }
  .swiper-container{
    grid-column: 1/span 1;
    grid-row: 2/span 1;
    width: 100vw;
    height: 80vh;
    .swiper-caption{
      display: block;
      font-weight: 500;
      @include fluid-type(font-size, 300px, 1600px, 14px, 16px);
      color: $white;
      background-color: rgba($black, .3);
      padding: $spacer-sm $page-margin;
    }
    .swiper-slide{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center 33%;
    }
    .swiper-button-prev, .swiper-button-next {
      color: $primary-color;
    }
    .swiper-pagination-bullet {
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 100%;
      background: $secondary-color;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: $primary-color;
    }
  }
  article{
    &.project-content{
      grid-column: 1/span 1;
      grid-row: 3/span 1;
    }
  }

  @media screen and (max-width: 768px) {
    .container{
      grid-template-rows: $header-size-mobile auto;
    }
    .swiper-container{
      height: 50vh;
    }
  }
}


// WORK DESCRIPTION

section#work-description {
  padding: $page-margin $page-margin 0 $page-margin;
  .container{
    display: grid;
    grid-template-columns: 66%;
  }
  article{
    &.project-content{
      grid-column: 1/span 1;
      grid-row: 3/span 1;
    }
  }

  @media screen and (max-width: 1024px) {
    .container{
      grid-template-columns: 100%;
    }
  }
}
