
/* ================================== NAVIGATION ================================== */
section.navigation {

	.header-bar{
		position: fixed;
		top: 0;
		left: 0;
    right: 0;
    background-color: $white;
		padding: 0 $page-margin;
		display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    "brand navigation";
    grid-gap: 0;
		height: $header-size;
		overflow: hidden;
		transition: all 0.3s ease;
		z-index: 900;
		&.header-bar-shrink{
			height: $header-size-mobile;
			transition: all 0.3s ease;
			.brand-wrapper{
        a{
          img{
  					&.logo{
  						width: 130px;
  						height: auto;
  						position: relative;
  						z-index: 30;
  						transition: opacity .3s ease;
  					}
  		  	}
        }
			}
		}
		.brand-wrapper{
			grid-area: brand;
			display: flex;
			align-items: center;
      a{
        line-height: 0;
        img{
  				&.logo{
  					width: 170px;
  					height: auto;
  					transition: opacity .3s ease;
  				}
  	  	}
      }
		}
  }
  .nav-wrapper{
		grid-area: navigation;
		display: flex;
    justify-content: flex-end;
		align-items: center;
		padding: 0;
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		  li {
        display: inline-block;
        margin: 0 0 0 20px;
        a {
          color: $black;
          font-family: $secondary_font;
          @include fluid-type(font-size, 300px, 1600px, 24px, 30px);
          font-weight: 700;
          text-decoration: none;
          transition: color .3s ease;
          letter-spacing: .02rem;
					i{
						font-weight: normal;
					}
          &:hover {
            color: $primary-color;
          }
					&.active {
            color: $primary-color;
          }
    		}
      }
    }
  }
	.nav-wrapper.visible {
		visibility: visible;
		transform: translateY(0);
	}
	.hamburger-button {
		position: relative;
		width: 25px;
		height: 20px;
		margin: 0;
		padding: 0;
		transition: .3s ease-in-out;
		display: none;
		z-index: 999;
	}
	.hamburger-button span {
		position: absolute;
		height: 3px;
		border-radius: 0;
		width: 25px;
		background-color: $black;
		top: 0;
		right:0;
		transition: color 0.3s ease;
		transition: transform .2s ease-in-out, opacity .2s ease-in-out;
	}
	.hamburger-button span:nth-child(1) {
		top: 0px;
	}
	.hamburger-button span:nth-child(2),
	.hamburger-button span:nth-child(3) {
		top: 8px;
	}
	.hamburger-button span:nth-child(4) {
		top: 16px;
	}
	.hamburger-button.active span {
		background-color: $black;
	}
	.hamburger-button.active span:nth-child(1),
	.hamburger-button.active span:nth-child(4) {
		opacity: 0;
	}
	.hamburger-button.active span:nth-child(2) {
		transform: rotate(45deg);
	}
	.hamburger-button.active span:nth-child(3) {
		transform: rotate(-45deg);
	}




  // MOBILE

	@media screen and (max-width: 768px) {
		.hamburger-button {
			display: flex;
			align-self: center;
		}
		.header-bar{
			height: $header-size-mobile;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
      "brand"
      "navigation";
			z-index: 20;
			.brand-wrapper{
				display: flex;
				justify-content: space-between;
				align-items: center;
		    img{
					&.logo{
						width: 80px;
						height: auto;
						margin-top: 10px;
						position: relative;
						z-index: 999;
						transition: opacity .3s ease;
					}
		  	}
			}
			.nav-wrapper{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				position: fixed;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				padding: $spacer $page-margin;
				background-color: $white;
				opacity: 0;
				transform: translateY(-100%);
				transition: all 0.3s ease;
				.brand-mark{
					position: relative;
					width: 120px;
					height: auto;
					margin-bottom: 2rem;
				}
				ul li {
					display: block;
					margin: 0;
					padding: 5px 0;
          z-index: 11;
				}
				ul li a {
					color: $black !important;
					font-size: 50px;
					font-weight: 700;
					text-decoration: none;
					transition: color .3s ease;
					letter-spacing: -.04rem;
          z-index: 800;
					&:hover{
						color: $primary-color;
            transition: color .3s ease;
					}
				}
		  }
			.nav-wrapper.visible {
				opacity: 1;
				transform: translateY(0);
				z-index: 900 !important;
			}
		}
	}
}
