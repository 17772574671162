// breakpoints
$sm:     576px;
$md:     768px;
$lg:     1200px;
$xl:     1600px;

// colors
$white:                 #FFFFFF !default;
$black:                 #1e1e24 !default;
$grey:                  #ECEBEA !default;

$primary-color:         #DF2935 !default;
$secondary-color:       #444140 !default;
$accent-color:          #d6d6b1 !default;



// fonts
$primary-font: "canada-type-gibson", sans-serif;
// 100/300/500/600

$secondary-font: "rift", sans-serif;
// 300/700


// spacers
$page-margin: 8vw;
$spacer: 4vw;
$spacer-sm: 10px;
$gutter: 30px;
$header-size: 140px;
$header-size-mobile: 90px;
